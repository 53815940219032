@font-face {
  font-family: "Aktiv Grotesk";
  src: url("../fonts/AktivGrotesk/DaltonMaag-AktivGrotesk-Medium.ttf")
    format("truetype");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Aktiv Grotesk";
  src: url("../fonts/AktivGrotesk/DaltonMaag-AktivGrotesk-Light.woff2")
      format("woff2"),
    url("../fonts/AktivGrotesk/DaltonMaag-AktivGrotesk-Light.woff")
      format("woff"),
    url("../fonts/AktivGrotesk/DaltonMaag-AktivGrotesk-Light.ttf")
      format("truetype");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Aktiv Grotesk";
  src: url("../fonts/AktivGrotesk/DaltonMaag-AktivGrotesk-Thin.woff2")
      format("woff2"),
    url("../fonts/AktivGrotesk/DaltonMaag-AktivGrotesk-Thin.woff")
      format("woff"),
    url("../fonts/AktivGrotesk/DaltonMaag-AktivGrotesk-Thin.ttf")
      format("truetype");
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: "Aktiv Grotesk";
  src: url("../fonts/AktivGrotesk/DaltonMaag-AktivGrotesk-Italic.woff2")
      format("woff2"),
    url("../fonts/AktivGrotesk/DaltonMaag-AktivGrotesk-Italic.woff")
      format("woff"),
    url("../fonts/AktivGrotesk/DaltonMaag-AktivGrotesk-Italic.ttf")
      format("truetype");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "Aktiv Grotesk";
  src: url("../fonts/AktivGrotesk/AktivGrotesk-Bold.eot")
    format("embedded-opentype");
  src: url("../fonts/AktivGrotesk/DaltonMaag-AktivGrotesk-Bold.woff")
      format("woff"),
    url("../fonts/AktivGrotesk/DaltonMaag-AktivGrotesk-Bold.ttf")
      format("truetype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Fakt Slab Pro";
  src: url("../fonts/FaktSlabPro/FaktSlabPro-Normal.eot"),
    url("../fonts/FaktSlabPro/FaktSlabPro-Normal.woff"),
    url("../fonts/FaktSlabPro/FaktSlabPro-Normal.otf"),
    url("../fonts/FaktSlabPro/FaktSlabPro-Normal.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Fakt Slab Pro";
  src: url("../fonts/FaktSlabPro/FaktSlabPro-NormalItalic.eot"),
    url("../fonts/FaktSlabPro/FaktSlabPro-NormalItalic.woff"),
    url("../fonts/FaktSlabPro/FaktSlabPro-NormalItalic.otf"),
    url("../fonts/FaktSlabPro/FaktSlabPro-NormalItalic.ttf");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Fakt Slab Pro";
  src: url("../fonts/FaktSlabPro/FaktSlabPro-Medium.eot"),
    url("../fonts/FaktSlabPro/FaktSlabPro-Medium.woff"),
    url("../fonts/FaktSlabPro/FaktSlabPro-Medium.otf"),
    url("../fonts/FaktSlabPro/FaktSlabPro-Medium.ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Fakt Slab Pro";
  src: url("../fonts/FaktSlabPro/FaktSlabPro-Blond.eot"),
    url("../fonts/FaktSlabPro/FaktSlabPro-Blond.woff"),
    url("../fonts/FaktSlabPro/FaktSlabPro-Blond.otf"),
    url("../fonts/FaktSlabPro/FaktSlabPro-Blond.ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Fakt Slab Pro";
  src: url("../fonts/FaktSlabPro/FaktSlabPro-BlondItalic.eot"),
    url("../fonts/FaktSlabPro/FaktSlabPro-BlondItalic.woff"),
    url("../fonts/FaktSlabPro/FaktSlabPro-BlondItalic.otf"),
    url("../fonts/FaktSlabPro/FaktSlabPro-BlondItalic.ttf");
  font-weight: 300;
  font-style: italic;
}
