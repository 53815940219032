/* Global */
*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

/* Defaults */
html {
  --font-size-html: 14px;
  font-size: var(--font-size-html);
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-size: inherit;
  font-family: Arial, sans-serif;
  font-weight: 400;
}

strong,
b {
  font-weight: 700;
}

@media screen and (min-width: 320px) {
  html {
    --font-size-html: calc(var(--font-size-html) + 6 * ((100vw - 320px) / 680));
  }
}

@media screen and (min-width: 1000px) {
  html {
    --font-size-html: 16px;
  }
}

/* Hack to disable Chrome autofill styling */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: all 0s 5000s;
  transition-delay: 5000s;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.body-title {
  font-size: 1rem;
}

p > a {
  text-decoration: underline;
}

a {
  text-decoration: none;
}
